import { useState } from "react";

export const clientSideFlags = {
  enableLiveVideoPausing: false,
} as const;

type ClientSideFlags = {
  [key in keyof typeof clientSideFlags]: boolean;
};

const useClientSideFeatureFlags = () => {
  const [flags, setFlags] = useState<ClientSideFlags>(clientSideFlags);

  // We don't do this in a useEffect because we want to set the flags
  // as soon as possible, and we don't want to wait for the component
  // to render.
  let fetchedFlags = {};
  try {
    fetchedFlags = JSON.parse(
      localStorage.getItem("clientSideFeatureFlags") || "{}"
    );
  } catch (e) {
    fetchedFlags = {};
  }

  if (JSON.stringify(flags) !== JSON.stringify(fetchedFlags)) {
    setFlags(fetchedFlags as ClientSideFlags);
  }

  const setFlag = (flagName: keyof ClientSideFlags, flagValue: boolean) => {
    const newFlags = {
      ...flags,
      [flagName]: flagValue,
    };
    setFlags(newFlags);

    try {
      localStorage.setItem("clientSideFeatureFlags", JSON.stringify(newFlags));
    } catch (e) {
      console.log("Error setting client side feature flag: ", e);
    }
  };

  return {
    flags: {
      enableLiveVideoPausing: (defaultFlag?: boolean) => {
        return flags.enableLiveVideoPausing || defaultFlag;
      },
    },
    setFlag: setFlag,
  };
};

export default useClientSideFeatureFlags;
