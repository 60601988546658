import { MarketingResource, Resource } from "@prisma/client";
import { definitions } from "../../types/supabase";
import videojs from "video.js";
import { isSupabaseResource } from "@/utils/cms/renderableResources";

function getR2Path(
  resource: definitions["resources_with_tags"] | Resource | MarketingResource
) {
  // the s3_path normally holds the filename of the video, but we don't want that
  // once it's been processed and pushed to r2

  // This allows us to handle either supabase resources OR prisma-fetched resources
  if (isSupabaseResource(resource)) {
    const s3_path = resource.s3_path?.split("/");
    s3_path?.pop();
    return s3_path?.join("/");
  } else {
    const s3_path = (resource as Resource).s3Path?.split("/");
    s3_path?.pop();
    return s3_path?.join("/");
  }
}

function getDuration(
  resource: definitions["resources_with_tags"] | Resource | MarketingResource
) {
  return (resource.metadata as any)?.duration || -1;
}

export function getTracks(
  resource: definitions["resources_with_tags"] | Resource | MarketingResource
) {
  const duration = getDuration(resource);
  const urlHost = getUrlHost(
    isSupabaseResource(resource) ? resource.s3_host : (resource as any).s3Host
  );
  const tracks =
    duration === -1 || duration >= 7200
      ? []
      : [
          {
            src: `${urlHost}/${getR2Path(resource)}/output/transcriptions/transcribe.vtt`,
            kind: "captions" as videojs.TextTrack.Kind,
            label: "English",
            srclang: "en",
          },
        ];

  return tracks;
}

function getUrlHost(host?: string) {
  return host && host === "cc-prd-retool"
    ? "https://www.hobbyresources.com"
    : "https://stg.hobbyresources.com";
}

export const getPosterImageForVideoJS = (
  resource: definitions["resources_with_tags"] | Resource | MarketingResource
) => {
  const path = getR2Path(resource);
  const urlHost = getUrlHost(
    isSupabaseResource(resource) ? resource.s3_host : (resource as any).s3Host
  );
  const url = `${urlHost}/${path}/output/poster/poster.jpg`;
  return url;
};
type VideoJSOptions = {
  hasThumbnails?: boolean;
};

const generateVideoJsOptions = (
  resource: definitions["resources_with_tags"] | Resource | MarketingResource,
  ccOptions: VideoJSOptions
) => {
  if (!resource) {
    return null;
  }

  const path = getR2Path(resource);
  const resolutions = (resource.metadata as any)?.available_resolutions || [];
  const urlHost = getUrlHost(
    isSupabaseResource(resource) ? resource.s3_host : (resource as any).s3Host
  );

  // @TODO: consider making this a brand configuration item
  const playBackRate = path?.includes("btb/")
    ? [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2]
    : [0.5, 1, 1.25, 1.5, 2];

  let sources = [];
  if ((resource.metadata as any)?.screenRecording === true) {
    sources = [
      {
        src: `${urlHost}/${path}/test.webm`,
        type: "video/mp4",
        label: "720p",
      },
    ];
  } else {
    sources = resolutions.map((resolution: any) => {
      const number = resolution.split("p")[0];
      return {
        src: `${urlHost}/${path}/output/${number}/video.mp4`,
        type: "video/mp4",
        label: resolution,
      };
    });
  }

  const plugins: { [key: string]: { src: string } } = {};
  if (ccOptions.hasThumbnails) {
    plugins["vttThumbnails"] = {
      src: `${urlHost}/${path}/output/thumbnails/thumbnails.vtt`,
    };
  }

  const options = {
    watermark: true,
    controls: true,
    fluid: true,
    playbackRates: playBackRate,
    sources,
    tracks: getTracks(resource),
    poster: getPosterImageForVideoJS(resource),
    plugins: plugins,
  };

  return options;
};

export default generateVideoJsOptions;
