/* eslint-disable @next/next/no-img-element */
import { useState } from "react";
import { definitions } from "../types/supabase";
import { CurrentEnvironment } from "../utils/CurrentEnvironment";
import { r2Hosts } from "./getUrl";
import { Resource } from "@prisma/client";
import { isSupabaseResource } from "@/utils/cms/renderableResources";

type CMSImageProps = {
  alt?: string;
  className?: string;
  image?: string | definitions["resources"] | Resource | null;
  imgClassName?: string;
};

export const CMSImage = ({
  alt,
  className,
  image,
  imgClassName,
}: CMSImageProps) => {
  // TODO: SEO placeholder
  // add method here to get the alt text based on image name and set a default if none is provided
  const getPipelineProcessedImage = (
    resource?: definitions["resources"] | Resource | string | null,
    fileType?: string
  ) => {
    if (!image) {
      return null;
    }

    if (!resource) {
      return null;
    }

    if (typeof resource === "string") {
      return resource;
    }

    if (isSupabaseResource(resource)) {
      const calculatedFileType =
        fileType || resource?.s3_path?.split(".").pop() || "";
      const r2Host = r2Hosts[CurrentEnvironment()];
      const parts = resource?.s3_path?.split("/") || [];
      parts.pop();
      parts.push("output");
      parts.push(calculatedFileType);
      parts.push("image." + calculatedFileType);
      return `${r2Host}/${parts.join("/")}`;
    } else {
      const calculatedFileType =
        fileType || resource?.s3Path?.split(".").pop() || "";
      const r2Host = r2Hosts[CurrentEnvironment()];
      const parts = resource?.s3Path?.split("/") || [];
      parts.pop();
      parts.push("output");
      parts.push(calculatedFileType);
      parts.push("image." + calculatedFileType);
      return `${r2Host}/${parts.join("/")}`;
    }
  };

  const webpUrl = getPipelineProcessedImage(image, "webp");
  const processedImg = getPipelineProcessedImage(image, "jpeg");

  let metadataValue;
  if (typeof image === "string") {
    metadataValue = image;
  } else if (image) {
    metadataValue = (image.metadata as { value?: string })?.value;
  }

  // webp is most performant
  // jpeg is next most performant (because we've processed it)
  // then we go to the image value which is the original image
  const [sources, setSources] = useState<Array<string>>(
    [webpUrl, processedImg, metadataValue, webpUrl].filter(
      Boolean
    ) as Array<string>
  );

  if (!image) {
    return null;
  }

  if (typeof image === "string") {
    // image may just be a string, often used in computing required data for tests, template pages, etc.
    return (
      <picture className={className}>
        <img alt={alt || ""} src={image} className={`w-full ${imgClassName}`} />
      </picture>
    );
  }

  if ((image.metadata as any)?.value) {
    return (
      <picture className={className}>
        <img
          alt={alt || ""}
          src={(image.metadata as { value?: string })?.value}
          className={`w-full ${imgClassName}`}
        />
      </picture>
    );
  }

  const handleImageLoadError = () => {
    const newSources = [...sources];
    newSources.shift();
    setSources(newSources);
  };

  return (
    <picture className={className} onError={handleImageLoadError}>
      {sources.map((srcString, i) => (
        <source key={srcString + i} srcSet={srcString} />
      ))}
      <img
        alt={alt || ""}
        src={webpUrl || ""}
        className={`w-full ${imgClassName}`}
      />
    </picture>
  );
};
