import { definitions } from "../types/supabase";
import { CurrentEnvironment } from "../utils/CurrentEnvironment";

export const r2Hosts = {
  local: "https://stg.hobbyresources.com",
  stg: "https://stg.hobbyresources.com",
  prd: "https://www.hobbyresources.com",
} as const;

export const getUrl = (
  obj:
    | { url: string }
    | definitions["resources"]
    | definitions["resources_with_tags"]
) => {
  if (!obj) {
    return undefined;
  }

  if (obj.url) {
    return obj.url;
  }

  return (
    r2Hosts[CurrentEnvironment()] +
    "/" +
    (obj as definitions["resources"]).s3_path
  );
};
