import { CurrentEnvironment } from "@/utils/CurrentEnvironment";
import getResourceBaseUrl from "@/utils/resources/getResourceBaseUrl";
import React from "react";

const Audio = ({
  className,
  source,
  type = "audio/mp3",
}: {
  className?: string;
  source: string;
  type?: string;
}) => {
  const hobbyLink = getResourceBaseUrl(CurrentEnvironment());
  return (
    <audio className={className} controls controlsList="nodownload">
      <source src={hobbyLink + "/" + source} type={type} />
      Your browser does not support the audio tag.
    </audio>
  );
};
export default Audio;
