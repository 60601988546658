const Requires = ({
  value,
  children,
}: {
  value: any;
  children: React.ReactNode;
}) => {
  if (!value) {
    return null;
  }

  return <>{children}</>;
};

export default Requires;
