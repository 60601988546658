/* eslint-disable react/display-name */
import Audio from "components/SubComponents/Audio";
import { CMSImage } from "../../components/CMSImage";
import { RichTextElements } from "../../components/SubComponents/RichText";
import Video, {
  CCVideoOptions,
  HandleVideoPlaybackEvent,
} from "../../components/SubComponents/Video";
import { definitions } from "../../types/supabase";
import { ScheduleType } from "../schedule/calculateSchedule";
import ResourceWithTags from "../types/resourceWithTags";
import { MarketingResource, Resource } from "@prisma/client";

export const injectMetadataValues = (
  resources: Array<definitions["resources"]>
) => {
  if (resources.length === 1) {
    return {
      ...(resources[0].metadata as { value?: any; values?: Array<any> }),
      ...resources[0],
      resources: resources.map((r: any) => {
        return {
          ...r,
          ...r.metadata?.value,
          ...(r.metadata || {}),
        };
      }),
    };
  } else {
    return {
      resources: resources.map((r: any) => {
        return {
          ...r,
          ...r.metadata?.value,
          ...(r.metadata || {}),
        };
      }),
    };
  }
};

export const getRawText = (resource: any): string => {
  if (typeof resource === "string") {
    return resource;
  }
  if (!resource) {
    return "";
  }
  return resource.metadata?.value || "";
};

export const getRawSchedule = (resource: any): ScheduleType | null => {
  if (!resource) {
    return null;
  }
  return resource.metadata?.value || null;
};

export function isSupabaseResource(
  resource:
    | definitions["resources"]
    | definitions["resources_with_tags"]
    | Resource
    | MarketingResource
): resource is definitions["resources"] {
  return (resource as definitions["resources"]).s3_host !== undefined;
}

export const getImageUrl = (
  resource: definitions["resources"] | Resource
): string => {
  if (!resource) {
    return "";
  }

  if ((resource.metadata as any)?.value) {
    return (resource.metadata as any).value;
  }

  // Once we get to only prisma types, we can remove this check, but for now, it allows
  // us to comingle the two types
  let s3FallbackUrl;
  if (isSupabaseResource(resource)) {
    s3FallbackUrl = `https://${resource?.s3_host}.s3.amazonaws.com/${resource?.s3_path}`;
  } else {
    s3FallbackUrl = `https://${resource.s3Host}.s3.amazonaws.com/${resource?.s3Path}`;
  }

  return s3FallbackUrl;
};

export const TextRender = ({
  resource,
  className,
}: {
  resource: any;
  className?: string;
}) => {
  if (!resource) {
    return null;
  }
  if (typeof resource === "string") {
    return renderText(resource as string)({ className });
  }

  return renderText(resource.metadata?.value)({ className });
};

export const RichTextRender = ({
  resource,
  className,
  element,
}: {
  resource: any;
  className?: string;
  element?: RichTextElements;
}) => {
  if (!resource) {
    return null;
  }
  if (typeof resource === "string") {
    return renderRichText(resource as string)({ className, element });
  }

  return renderRichText(resource.metadata?.value)({ className, element });
};

export const ImageRender = ({
  resource,
  className,
  imgClassName,
  alt,
}: {
  resource: any;
  className?: string;
  imgClassName?: string;
  alt?: string;
}) => {
  if (resource) {
    return renderImage(resource)({ className, imgClassName, alt });
  }
  return null;
};

type VideoRenderProps = {
  resource: ResourceWithTags | definitions["resources"] | Resource;
  brandAbbreviation: string;
  handleVideoPlaybackEvent?: HandleVideoPlaybackEvent;
  startAt?: number;
  autoplay?: boolean;
  options?: CCVideoOptions;
};

export const VideoRender = ({
  resource,
  brandAbbreviation,
  handleVideoPlaybackEvent,
  startAt,
  autoplay,
  options,
}: VideoRenderProps) => {
  return renderVideo(resource)({
    brandAbbreviation,
    handleVideoPlaybackEvent,
    startAt,
    autoplay,
    options,
  });
};

export const AudioRender = ({
  className,
  source,
}: {
  className?: string;
  source: string;
}) => {
  return <Audio className={className} source={source} />;
};

function renderRichText(richtext: string): (args?: any) => JSX.Element {
  return ({
    className,
    element,
  }: { className?: string; element?: RichTextElements } = {}) => {
    const Element = element || "div";
    return (
      <Element
        dangerouslySetInnerHTML={{ __html: richtext || "" }}
        className={className || ""}
      />
    );
  };
}

function renderImage(resource: any): (args: any) => JSX.Element {
  return ({
    className,
    imgClassName,
  }: {
    className?: string;
    imgClassName?: string;
  }) => {
    return (
      <CMSImage
        image={resource}
        alt={resource?.metadata?.altTag || ""}
        className={className || ""}
        imgClassName={imgClassName || ""}
      />
    );
  };
}

function renderText(
  text: string
): ({ className }: { className?: string }) => JSX.Element {
  return ({ className }: { className?: string } = {}) => {
    return <span className={className}>{text}</span>;
  };
}

function renderVideo(
  resource: ResourceWithTags | definitions["resources"] | Resource
): (args: any) => JSX.Element {
  return ({
    brandAbbreviation,
    handleVideoPlaybackEvent,
    startAt,
    autoplay,
    options,
  }: {
    brandAbbreviation: string;
    handleVideoPlaybackEvent?: HandleVideoPlaybackEvent;
    startAt?: number;
    autoplay?: boolean;
    options: CCVideoOptions;
  }) => {
    return (
      <Video
        videoResource={resource}
        brandAbbreviation={brandAbbreviation}
        onVideoPlaybackEvent={handleVideoPlaybackEvent}
        startAt={startAt}
        autoplay={autoplay}
        ccVideoOptions={options}
      />
    );
  };
}
