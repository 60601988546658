import { useEffect, useRef, useState } from "react";
import { definitions } from "../../types/supabase";
import generateVideoJsOptions from "../Video/getVideoOptions";
import videojs, { VideoJsPlayer } from "video.js";
import "video.js/dist/video-js.css";
import useClientSideFeatureFlags from "../../utils/clientSideFeatureFlags";
import Requires from "../../internal_components/Requires";
import { MarketingResource, Resource } from "@prisma/client";

export type VideoTrackingEvent = {
  status:
    | "video_playback_error"
    | "video_playback_started_successfully"
    | "fullscreen"
    | "inline";
  isPlaying: boolean;
  currentTime: number;
  audioState: "audio_enabled" | "audio_muted";
  error?: string;
  videoId: string;
};

export type CCVideoOptions = {
  lockedPlayback?: boolean;
  blockFullscreen?: boolean;
  canAutoPlayWithSound?: boolean;
  onVideoEvent: (onVideoEvent: VideoTrackingEvent) => void;
};

export type useVideoJsOptions = {
  videoResource: definitions["resources"] | Resource | MarketingResource;
  brandAbbreviation: string;
  onVideoPlaybackEvent?: HandleVideoPlaybackEvent;
  startAt?: number;
  autoplay?: boolean;
  ccVideoOptions?: CCVideoOptions;
  setVideoPlayer?: Function;
};

export type HandleVideoPlaybackEvent = (event: VideoPlaybackEvent) => void;
export type VideoPlaybackEvent = { currentTime: number; totalTime: number }; // current time is offset in seconds

require("videojs-vtt-thumbnails");

const Video = ({
  videoResource,
  brandAbbreviation,
  onVideoPlaybackEvent,
  startAt,
  autoplay,
  ccVideoOptions,
  setVideoPlayer,
}: useVideoJsOptions) => {
  const videoRef = useRef<any | null>(null);
  const playerRef = useRef<any | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [error, setError] = useState("");
  const [showMuteIndicator, setShowMuteIndicator] = useState(false);

  // [TODO]: figure out how these are different from
  // the newly implemented feature flagging setup and merge them together
  const { flags } = useClientSideFeatureFlags();

  useEffect(() => {
    if (!playerRef?.current && videoResource) {
      const videoElement = videoRef.current;
      if (!videoElement) {
        return;
      }

      let options = null;
      try {
        options = generateVideoJsOptions(videoResource, {
          hasThumbnails: !ccVideoOptions?.lockedPlayback,
        });
      } catch (e: any) {
        setError(e.message);
        console.error(e);
      }

      if (options !== null) {
        setShowVideo(true);
      } else {
        console.log(
          "Error creating options for video. Resource: ",
          videoResource
        );
      }

      const controlBarIconOrder = [
        ccVideoOptions?.lockedPlayback && !flags.enableLiveVideoPausing()
          ? ""
          : "playToggle",
        "volumePanel",
        ccVideoOptions?.lockedPlayback && !flags.enableLiveVideoPausing()
          ? ""
          : "progressControl",
        "currentTimeDisplay",
        "timeDivider",
        ccVideoOptions?.lockedPlayback ? "" : "durationDisplay",
        "chaptersButton",
        ccVideoOptions?.lockedPlayback ? "" : "playbackRateMenuButton",
        "subsCapsButton",
        "qualitySelector",
      ].filter(Boolean);

      const onReady = (player: VideoJsPlayer) => {
        let resumeAfterErrorAttempt: NodeJS.Timer | null = null;
        let lastTimeSeen = 0;

        setInterval(() => {
          if (player.paused()) {
            return;
          } // don't do anything if the video is paused

          const currentTime = player.currentTime();
          if (currentTime > 0) {
            // don't do anything if the video hasn't started yet
            const outOfSyncByFiveSeconds =
              Math.abs(lastTimeSeen - currentTime) > 5;
            const playbackIsExactlyLastSeen = currentTime === lastTimeSeen; // if they are _exactly_ the same, then we know the video is stuck

            if (outOfSyncByFiveSeconds || playbackIsExactlyLastSeen) {
              setError(
                "Video playback error detected. Attempting to resume playback."
              );
            } else {
              setError("");
            }

            lastTimeSeen = currentTime;
          }
        }, 1000);

        if (resumeAfterErrorAttempt) {
          clearInterval(resumeAfterErrorAttempt);
        }

        player.on("error", function (_event) {
          ccVideoOptions?.onVideoEvent({
            status: "video_playback_error",
            isPlaying: false,
            currentTime: player.currentTime(),
            audioState: player.muted() ? "audio_muted" : "audio_enabled",
            error:
              "Video playback error detected. Attempting to auto-recover playback.",
            videoId: videoResource.id,
          });

          const time = player.currentTime();

          if (resumeAfterErrorAttempt) {
            clearInterval(resumeAfterErrorAttempt);
          }

          resumeAfterErrorAttempt = setInterval(() => {
            player.error(null); // clear the error
            player.pause();
            player.load(); // reload the video
            player.currentTime(time); // set the time to where it was before
            player.play(); // play it again
            if (resumeAfterErrorAttempt) {
              clearInterval(resumeAfterErrorAttempt);
            } // prevent this from continuing to run every second
          }, 1000);
        });

        player.currentTime(startAt || 0);

        if (autoplay) {
          // if we can't autoplay with sound, then mute it - browsers don't allow you to autoplay with sound if the user hasn't interacted with the page yet
          player.muted(!ccVideoOptions?.canAutoPlayWithSound);

          // sometimes need to give it a little bit to be ready to play, even though it's the `onReady` callback :upside_down_face:
          setTimeout(() => {
            const promise = player.play();

            if (promise !== undefined) {
              promise
                .then(function () {
                  ccVideoOptions?.onVideoEvent({
                    isPlaying: true,
                    currentTime: player.currentTime(),
                    audioState: player.muted()
                      ? "audio_muted"
                      : "audio_enabled",
                    videoId: videoResource.id,
                    status: "video_playback_started_successfully",
                  });
                })
                .catch(function (error) {
                  ccVideoOptions?.onVideoEvent({
                    isPlaying: false,
                    currentTime: player.currentTime(),
                    audioState: player.muted()
                      ? "audio_muted"
                      : "audio_enabled",
                    error:
                      "Unable to start video with audio. Trying again muted.",
                    videoId: videoResource.id,
                    status: "video_playback_error",
                  });

                  window.Rollbar?.info(
                    error,
                    "Error playing video with sound, attempting to play muted. " +
                      "This is recoverable, so not an error we have to fix, but is useful for knowing how many people it affects."
                  );

                  // Try again, but this time with the player muted
                  player.muted(true);
                  const fallbackMutedPromise = player.play();

                  if (fallbackMutedPromise !== undefined) {
                    fallbackMutedPromise
                      .then(function () {
                        ccVideoOptions?.onVideoEvent({
                          isPlaying: true,
                          currentTime: player.currentTime(),
                          audioState: player.muted()
                            ? "audio_muted"
                            : "audio_enabled",
                          videoId: videoResource.id,
                          status: "video_playback_started_successfully",
                        });

                        window.Rollbar?.info(
                          "Video recovered and was able to be played without sound."
                        );
                        setShowMuteIndicator(true);
                      })
                      .catch(function (error) {
                        window.Rollbar?.error(
                          error,
                          "Error playing video muted. This is not easily recoverable."
                        );
                        ccVideoOptions?.onVideoEvent({
                          isPlaying: false,
                          currentTime: player.currentTime(),
                          audioState: player.muted()
                            ? "audio_muted"
                            : "audio_enabled",
                          error:
                            "Unable to start video without audio. Unable to autoplay video on this browser.",
                          videoId: videoResource.id,
                          status: "video_playback_error",
                        });
                      });
                  }
                });
            }
          }, 500);
        }

        player.playsinline(true);
      };

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          // disable click on video to pause if lockedPlayback
          userActions: {
            click: flags.enableLiveVideoPausing(
              !ccVideoOptions?.lockedPlayback
            ),
          },
          controlBar: { children: controlBarIconOrder },
        },
        () => {
          onReady(player);
        }
      ));

      // set the player on the parent component if needed
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setVideoPlayer && setVideoPlayer(player);

      player.on("fullscreenchange", (e) => {
        const isFullScreen = e.target === document.fullscreenElement;
        ccVideoOptions?.onVideoEvent({
          isPlaying: player.paused() === false && player.ended() === false,
          currentTime: player.currentTime(),
          audioState: player.muted() ? "audio_muted" : "audio_enabled",
          videoId: videoResource.id,
          status: isFullScreen ? "fullscreen" : "inline",
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoRef,
    brandAbbreviation,
    videoResource,
    ccVideoOptions,
    autoplay,
    startAt,
    flags,
  ]);

  useEffect(() => {
    const reportTime = setInterval(() => {
      onVideoPlaybackEvent &&
        onVideoPlaybackEvent({
          currentTime: Math.floor(playerRef.current?.currentTime()),
          totalTime: Math.floor(playerRef.current?.duration()),
        });
    }, 500);

    return () => {
      if (reportTime) {
        clearInterval(reportTime);
      }
    };
  }, [playerRef, onVideoPlaybackEvent]);

  const unmutePlayer = () => {
    playerRef.current?.muted(false);
    setShowMuteIndicator(false);
  };

  const renderedError = error && (
    <div className="text-white bg-primary text-center p-4">{error}</div>
  );

  const renderedVideoElement = (
    <div
      className="w-full flex flex-col relative"
      onContextMenu={(e) => e.preventDefault()}
    >
      {renderedError}
      <Requires value={showMuteIndicator}>
        <div
          className="absolute bottom-8 left-12 z-10 bg-white p-1 px-2 rounded shadow cursor-pointer hover:bg-slate-100"
          onClick={unmutePlayer}
        >
          Click to unmute
        </div>
      </Requires>
      <div className={`w-full ${showVideo ? "" : "hidden"}`}>
        <video
          className={`video-js vjs-big-play-centered cursor-auto`}
          ref={videoRef}
        />
      </div>
    </div>
  );

  return renderedVideoElement;
};

export default Video;
